<template>
    <div class="container-fluid main-color">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4
              class="page-title"
              v-if="currentRouteName == 'hrd-ojt-assessment-getEditId'"
            >
              Edit Record
            </h4>
            <h4 class="page-title" v-else>Add Record</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-lg-12">
          <place-holder v-if="loading"></place-holder>
          <div class="card">
            <div v-if="!loading" class="card-body">
              <form class="parsley-examples">
                <div class="row">
                  <div class="col-md-7">
                    <div class="row mb-3">
                      <label for="name" class="col-md-3 form-label">
                        Program Name
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="assessments.program_name"
                          disabled
                        />
                      </div>
                    </div>
  
                    <div class="row mb-3">
                      <label for="short_name" class="col-md-3 form-label">
                        Employee Name
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="assessments.employee_name"
                          disabled
                        />
                      </div>
                    </div>
  
                    <div class="row mb-2">
                      <label for="short_name" class="col-md-3 form-label">
                        Employee ID
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="assessments.employee_id"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>
  
                    <div class="row mb-2">
                      <label for="short_name" class="col-md-3 form-label">
                        Level
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          v-model="assessments.level"
                          class="form-control"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
  
                  <div class="row col-md-5">
                    <div class="row mb-3">
                      <label for="short_name" class="col-md-4 form-label">
                        Batch
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="assessments.batch_no"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="date" class="col-md-4 form-label">
                        Joined Date
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-8">
                        <input
                          type="text"
                          class="form-control"
                          v-model="assessments.join_date"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        for="assessment_criteria"
                        class="col-md-4 form-label"
                      >
                      Choose Assessment Criteria
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-8">
                        <v-select
                          @option:selected="getSelectedCriteria($event)"
                          label="name"
                          :options="criteriaPoints"
                          :selectable="(options) => isSelectableCriteria(options)"
                          :reduce="(point) => point.name"
                          :clearable="false"
                          v-model="selectedPoint"
                          :class="{
                            'p-invalid':
                              v$.selectedPoint.$error ||
                              errorFor('Criteria Point'),
                          }"
                        >
                        </v-select>
                        <v-errors
                          :serverErrors="errorFor('Criteria Point')"
                          :vuelidateErrors="{
                            errors: v$.selectedPoint.$errors,
                            value: 'Criteria Point',
                          }"
                        ></v-errors>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div
                  class="progress mt-3"
                  style="height: 0.5px; background-color: #ccc !important"
                >
                  <div class="progress-bar" role="progressbar"></div>
                </div>
  
                <div class="row g-3 mt-3">
                  <div class="col-sm-4">
                    <h4 class="mb-3">Criteria</h4>
                  </div>
                  <div class="col-sm-4">
                    <h4 class="mb-3">Scores:*</h4>
                  </div>
                  <div class="col-sm-4">
                    <h4 class="mb-3">Remark:</h4>
                  </div>
                </div>
  
                <div
                  class="row g-3"
                  v-for="(cri, index) in criteria"
                  :key="index"
                >
                  <div class="col-sm-4">
                    <p class="mb-3 pt-2 pl-2">{{ cri.name }}</p>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <v-select
                        v-model="cri.selectedScore"
                        :options="scoreOptions(cri)"
                        :clearable="false"
                        @option:selected="updateTotalScore"
                        
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-2">
                      <input
                        type="text"
                        class="form-control mt-2"
                        placeholder="Remark"
                        aria-label="Zip"
                        v-model="cri.remark"
                      />
                    </div>
                  </div>
                </div>
                <div class="row g-3 pt-2">
                  <div class="col-sm-4">Achivement</div>
                  <div class="col-sm-4">
                    <input
                      type="text"
                      class="form-control"
                      v-model="total_score"
                      disabled
                    />
                  </div>
  
                  <div class="col-sm-4">
                    <input
                      type="text"
                      class="form-control"
                      v-model="hrd_score"
                      disabled
                    />
                  </div>
                </div>
  
                <div class="row g-3 pt-2">
                  <div class="col-sm-4">Hrd Assessment</div>
                  <div class="col-sm-4">
                    <v-select
                      v-model="selectedPercentagePoint"
                      label="name"
                      :options="percentagePoint"
                      :clearable="false"
                      disabled
                    >
                    </v-select>
                  </div>
                </div>
  
                <div class="row g-3 pt-3">
                  <div class="col-sm-4">Result</div>
                  <div class="col-sm-4">
                    <input
                      type="text"
                      class="form-control"
                      v-model="calculationPoint"
                      disabled
                    />
                    <small class="text-success" v-if="point != 0"
                      >Calculate point - {{ point }}</small
                    >
                  </div>
                  <div class="col-sm-4">
                    <p class="checkClick" @click="showCheck()">Check</p>
                  </div>
                </div>
  
                <!-- <div class="mt-5">
                  <div class="font-weight-bold">
                    <h5>Main Job & responsiilities</h5>
                    <div style="height: 200px">
                      <quill-editor
                        theme="snow"
                        v-model:content="main_job_responsibility"
                        :toolbar="toolbarOptions"
                        contentType="html"
                      ></quill-editor>
                    </div>
                  </div>
                </div> -->
  
                <!-- end row -->
                <div class="row mt-4">
                  <div class="col-md-12">
                    <div class="text-center mt-3 mb-3">
                      <router-link :to="{ name: 'hrd-ruleregulation-assessment' }">
                        <button type="button" class="btn w-sm btn-secondary me-5">
                          Cancel
                        </button>
                      </router-link>
                      <button
                        v-if="calculationPoint == 0"
                        style="cursor: not-allowed"
                        data-bs-toggle="tooltip"
                        title="You need to check your point first."
                        type="button"
                        class="btn w-sm btn-success waves-effect waves-light px-3"
                      >
                        <span>Save</span>
                      </button>
                      <button
                        v-else
                        :disabled="isLoading ? true : false"
                        type="button"
                        class="btn w-sm btn-success waves-effect waves-light px-3"
                        @click="saveOjtAssessment()"
                      >
                        <span
                          v-if="isLoading"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {{ isLoading == true ? "Loading..." : "Save" }}
                      </button>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import useVuelidate from "@vuelidate/core";
  import { useToast } from "vue-toastification";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { required } from "@vuelidate/validators";
  import validationErrors from "../../../mixins/validationErrors";
  import { hrd_suggestions } from "../../../utlis/hrd_suggestions";
  
  export default {
    setup() {
      const toast = useToast();
      return { v$: useVuelidate(), toast };
    },
    components: {
      vSelect,
    },
    mixins: [validationErrors],
    data() {
      return {
        total_score: 0,
        point: 0,
        calculationPoint: 0,
        selectedScores: [],
        assessments: {},
        criterias: [],
        criteria: [],
        criteriaPoints: [],
        main_job_responsibility: "",
        total_criteria_score: 0,
        hrd_score: 0,
        selectedPoint: null,
        assessmentCriteriaId: null,
        selectedPercentagePoint: {
          id: 1,
          name: "Poor",
          highest_point: 49,
          lowest_point: 0,
        },
        percentagePoint: hrd_suggestions,
        baseUrl: process.env.VUE_APP_BASE_URL,
        baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
        loading: false,
        isLoading: false,
        toolbarOptions: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          // ["code-block", "link"],
          // [{ font: [] }],
          // ["link", "video", "image"],
          [
            "bold",
            "italic",
            "underline",
            "strike",
            { list: "ordered" },
            { list: "bullet" },
            { align: [] },
          ],
        ],
      };
    },
    validations() {
      return {
        selectedPoint: { required },
      };
    },
    methods: {
      isSelectableCriteria(options) {
        if (!this.selectedPoint || !options) return true;
        return this.selectedPoint != options.name;
      },
      showCheck() {
        if (this.criteria.length == 0) return;
        let totalHeightScore = this.criteria.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue.highest_score;
          },
          0
        );
        this.calculationPoint = Math.round(
          (((this.total_score / totalHeightScore) * this.point) / 100) * 100
        );
      },
      async getAssessmentData() {
        this.loading = true;
        this.$Progress.start();
        await axios
          .get(
            `${this.baseUrl}admin/v2/hrd-assessment/${this.$route.params.id}?hrd_type=Rule`
          )
          .then((response) => {
            this.assessments = response.data.data;
            this.getAllCriteria();
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.toast.error("Page not found.");
              this.$router.push("page-not-found");
            } else {
              this.errors = error.response;
              this.toast.error("Not Criteria Found!");
              this.isLoading = false;
            }
          });
      },
      async getAllCriteria() {
        await axios
          .get(
            `${this.baseUrl}admin/v2/hrd-assessment-criteria?program_id=${this.assessments.program_id}&level=${this.assessments.level}&type=OJT&hrd_type=Rule`
          )
          .then((response) => {
            let data = response.data.criteria;
            // console.log(data)
            for (let i = 0; i < data.length; i++) {
              this.criteriaPoints.push({
                id: data[i].id,
                assessment_criteria_id: data[i].id,
                name: data[i].name,
                point: data[i].point,
              });
              if (data[i].id == this.assessmentCriteriaId) {
                this.selectedPoint = data[i].name;
              }
  
              let hrdOjt = data[i].hrd_criteria;
  
              for (let j = 0; j < hrdOjt.length; j++) {
                this.criterias.push({
                  id: hrdOjt[j].id,
                  name: hrdOjt[j].name,
                  assessment_criteria_id: hrdOjt[j].assessment_criteria_id,
                  lowest_score: hrdOjt[j].lowest_score,
                  highest_score: hrdOjt[j].highest_score,
                  remark: null,
                  selectedScore: hrdOjt[j].lowest_score,
                });
              }
              // this.point = response.data.criteria.point;
            }
  
            this.loading = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.toast.error("Page not found.");
              this.$router.push("page-not-found");
            } else {
              this.errors = error.response;
              this.toast.error("Something Went Wrong!");
              this.isLoading = false;
            }
          });
      },
      async saveOjtAssessment() {
        const isFormCorrect = await this.v$.$validate();
        if (!isFormCorrect) return;
  
        let id = this.$route.params.id;
        let hrdOjtCriterias = [];
        let totalHeightScore = this.criteria.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue.highest_score;
          },
          0
        );
        this.calculationPoint = Math.round(
          (((this.total_score / totalHeightScore) * this.point) / 100) * 100
        );
  
        this.criteria.forEach((item) => {
          hrdOjtCriterias.push({
            hrd_criteria_id: item.id,
            score: item.selectedScore,
            remark: item.remark,
            // point: this.calculationPoint,
            // hrd_score: this.hrd_score,
            // hrd_suggestion: this.selectedPercentagePoint.name,
          });
        });
        let toSendData = {
          "assessment_criteria_id": this.criteria[0].assessment_criteria_id,
          "result_score": this.calculationPoint,
          "hrd_score": this.hrd_score,
          "hrd_type": 'Rule',
          "assessment_point": this.point,
          "assessment_detail_id": id,
          "achievement": this.total_score,
          "hrd_suggestion": this.selectedPercentagePoint.name,
          "hrd_criterias": hrdOjtCriterias,
          "statusAssessment": this.assessments.status,
          "main_job_responsibility": this.main_job_responsibility,
        };
        this.isLoading = true;
  
        if (this.currentRouteName == "hrd-ruleregulation-assessment-getEditId") {
          await axios
            .put(`${this.baseUrl}admin/v2/hrd-assessment/${id}`, toSendData, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then(() => {
              this.$router.push({ name: "hrd-ruleregulation-assessment" });
              this.toast.success("Successfully Saved Rule & Regulation Assessment!");
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.status === 404) {
                this.toast.error("Page not found.");
                this.$router.push("page-not-found");
              } else {
                this.errors = error.response;
                this.toast.error("Something Went Wrong!");
                this.isLoading = false;
              }
            });
        }else{
          await axios
            .post(`${this.baseUrl}admin/v2/hrd-assessment`, toSendData, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then(() => {
              this.$router.push({ name: "hrd-ruleregulation-assessment" });
              this.toast.success("Successfully Saved Rule Regulation Assessment!");
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.status === 404) {
                this.toast.error("Page not found.");
                this.$router.push("page-not-found");
              } else {
                this.errors = error.response;
                this.toast.error("Something Went Wrong!");
                this.isLoading = false;
              }
            });
        }
        
      },
  
      updateTotalScore() {
        let totalScore = 0;
        for (let i = 0; i < this.criteria.length; i++) {
          totalScore += this.criteria[i].selectedScore;
        }
        this.total_score = totalScore;
        this.hrd_score = Math.round(
          (this.total_score / this.total_criteria_score) * 100
        );
        this.calculatePercentageSuggestion();
      },
  
      calculatePercentageSuggestion() {
        this.percentagePoint.forEach((item) => {
          if (
            this.hrd_score <= item.highest_point &&
            this.hrd_score >= item.lowest_point
          ) {
            return (this.selectedPercentagePoint = item);
          }
        });
      },
  
      scoreOptions(cri) {
        const options = [];
        for (let i = cri.lowest_score; i <= cri.highest_score; i++) {
          options.push(i);
        }
        return options;
      },
  
      async getEditById() {
        this.loading = true;
        this.$Progress.start();
        let id = this.$route.params.id;
        await this.getAssessmentData();
        await axios
          .get(
            `${this.baseUrl}admin/v2/hrd-assessment/${id}?hrd_type=Rule` //1 means completed state / status if(status == 0) to do state/
          )
          .then((response) => {
            // console.log(response.data)
            response.data.data.hrd_criterias.forEach((item) => {
              this.criteria.push({
                id: item.hrd_criteria_id,
                assessment_criteria_id: response.data.data.assessment_criteria.id,
                name: item.name,
                lowest_score: item.lowest_score,
                highest_score: item.highest_score,
                remark: item.remark,
                selectedScore: item.score,
              });
              this.total_score += Number(item.score);
              this.total_criteria_score += Number(
                item.highest_score
              );
            });
            this.main_job_responsibility = response.data.data.main_job_responsibility;
            this.hrd_score = Number(
              response.data.data.hrd_score
            );
            this.selectedPoint =
              response.data.data.assessment_criteria.name;
  
            this.point =
              response.data.data.assessment_point;
  
            this.calculationPoint =
              response.data.data.result_score;
  
            this.calculatePercentageSuggestion();
  
            this.showCheck();
            
  
          })
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              this.toast.error("Page not found.");
              this.$router.push("page-not-found");
            } else {
              this.errors = error.response;
              this.toast.error("Something Went Wrong!");
              this.isLoading = false;
            }
          });
      },
  
      getSelectedCriteria(val) {
        this.criteria = [];
        this.total_score = 0;
        this.calculationPoint = 0;
  
        if (val.assessment_criteria_id == this.assessmentCriteriaId) {
          this.criteria = this.editCriteria;
          this.total_score = this.criteria.reduce(
            (total, cri) => total + cri.selectedScore,
            0
          );
          return;
        }
  
        this.criterias.forEach((cri) => {
          if (val.assessment_criteria_id == cri.assessment_criteria_id) {
            this.criteria.push({
              id: cri.id,
              name: cri.name,
              assessment_criteria_id: cri.assessment_criteria_id,
              lowest_score: cri.lowest_score,
              highest_score: cri.highest_score,
              remark: null,
              selectedScore: cri.lowest_score,
            });
            this.total_score += Number(cri.lowest_score);
            this.total_criteria_score += Number(cri.highest_score);
          }
        });
        this.point = val.point;
      },
    },
  
    created() {
      // console.log(this.currentRouteName)
      if (this.currentRouteName == "hrd-ruleregulation-assessment-getEditId") {
        this.getEditById();
      } else {
        this.getAssessmentData();
      }
    },
  
    computed: {
      currentRouteName() {
        return this.$route.name;
      },
    },
  };
  </script>
  
  <style>
  .custom-selectCSS {
    --vs-dropdown-min-width: 160px;
    --vs-dropdown-max-height: 200px;
  }
  .selectScore {
    padding: 0.45rem 0.9rem;
  }
  .checkClick {
    cursor: pointer;
    font-weight: bold;
    margin-top: 3px;
    user-select: none;
    font-size: 1.1em;
  }
  .checkClick:hover {
    font-weight: 300;
  }
  </style>
  