export const hrd_suggestions = [
    { id: 1, name: "Poor", highest_point: 49, lowest_point: 0 },
    {
      id: 2,
      name: "Need Improvement",
      highest_point: 69,
      lowest_point: 50,
    },
    {
      id: 3,
      name: "Meet Expectation",
      highest_point: 79,
      lowest_point: 70,
    },
    {
      id: 4,
      name: "Exceed Expectation",
      highest_point: 89,
      lowest_point: 80,
    },
    { id: 5, name: "Excellent", highest_point: 100, lowest_point: 90 },
  ]